import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/",
    name: "LoginView",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/app",
    name: "AppLayout",
    component: () => import("../layouts/AppLayout.vue"),
    children: [
      {
        path: "",
        name: "HomeView",
        component: () => import("../views/HomeView.vue"),
      },
      {
        path: "chats",
        name: "ChartView",
        component: () => import("../views/ChartView.vue"),
      },
      {
        path: "users",
        name: "UsersView",
        component: () => import("../views/UsersView.vue"),
        children: [
          {
            path: "add",
            name: "UserAddView",
            component: () => import("../views/UserUpdateView.vue"),
          },
          {
            path: "update",
            name: "UserUpdateView",
            component: () => import("../views/UserUpdateView.vue"),
          },
          {
            path: "remove",
            name: "UserRemoveView",
            component: () => import("../views/UserRemoveView.vue"),
          },
          {
            path: "add-session",
            name: "UserSessionUpdateView",
            component: () => import("../views/SessionUpdateView.vue"),
          },
        ],
      },
      {
        path: "people",
        name: "PeopleView",
        component: () => import("../views/PeopleView.vue"),
        children: [
          {
            path: "add",
            name: "PersonAddView",
            component: () => import("../views/PersonUpdateView.vue"),
          },
          {
            path: "update",
            name: "PersonUpdateView",
            component: () => import("../views/PersonUpdateView.vue"),
          },
          {
            path: "remove",
            name: "PersonRemoveView",
            component: () => import("../views/PersonRemoveView.vue"),
          },
          {
            path: "list-sessions",
            name: "PersonSessionsView",
            component: () => import("../views/PersonSessionsView.vue"),
          },
          {
            path: "add-session",
            name: "PersonSessionAddView",
            component: () => import("../views/SessionUpdateView.vue"),
          },
        ],
      },
      {
        path: "sessions",
        name: "SessionsView",
        component: () => import("../views/SessionsView.vue"),
        children: [
          {
            path: "add",
            name: "SessionAddView",
            component: () => import("../views/SessionUpdateView.vue"),
          },
          {
            path: "update",
            name: "SessionUpdateView",
            component: () => import("../views/SessionUpdateView.vue"),
          },
          {
            path: "remove",
            name: "SessionRemoveView",
            component: () => import("../views/SessionRemoveView.vue"),
          },
        ],
      },
      {
        path: "settings",
        name: "SettingsView",
        component: () => import("../views/SettingsView.vue"),
      },
      {
        path: "info",
        name: "InfoView",
        component: () => import("../views/InfoView.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: "AppTestLayout",
    component: () => import("../layouts/AppLayout.vue"),
    children: [
      {
        path: "",
        name: "TemplateView",
        component: () => import("../views/test/TemplateView.vue"),
      },
      {
        path: "user-form",
        name: "UserFormView",
        component: () => import("../views/test/UserFormView.vue"),
      },
      {
        path: "person-form",
        name: "PersonFormView",
        component: () => import("../views/test/PersonFormView.vue"),
      },
      {
        path: "u-table",
        name: "UTableView",
        component: () => import("../views/test/UTableView.vue"),
      },
    ],
  },
] as Array<RouteRecordRaw>;
