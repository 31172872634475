import { initializeApp, getApps } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_APP_NAME + ".firebaseapp.com",
  projectId: process.env.VUE_APP_FIREBASE_APP_NAME,
  storageBucket: process.env.VUE_APP_FIREBASE_APP_NAME + ".appspot.com",
  messagingSenderId: process.env.VUE_APP_FIREBASE_APP_NAME,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
const firebaseTestConfig = {
  apiKey: "AIzaSyCET_odUY7caxsoOyCPk9uM8ZLIJJKdOHQ",
  authDomain: "therapy-e1ba6.firebaseapp.com",
  projectId: "therapy-e1ba6",
  storageBucket: "therapy-e1ba6.appspot.com",
  messagingSenderId: "94620636718",
  appId: "1:94620636718:web:12d785349faf816b4871bf",
};

export const app = initializeApp(firebaseConfig);
export const appTest = initializeApp(firebaseTestConfig, "test");

export { getApps };
