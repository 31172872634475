import { app, appTest } from "@/services/firebase/app";
import { UpdateFieldValue } from "@/types/firebase";

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  orderBy,
  startAfter,
  getDocs,
  limit,
  where,
  or,
  addDoc,
  updateDoc,
  deleteDoc,
  increment,
  getCountFromServer,
  getAggregateFromServer,
  count,
  sum,
  startAt,
} from "firebase/firestore";

import dot from "dot-object";
import { USER } from "@/constraints";

const db = getFirestore(app);
const dbTest = getFirestore(appTest);
dot.keepArray = true;

const isProd = true;
export const getDocument = function (col: string, document: string) {
  const docRef = doc(isProd ? db : col == USER ? db : dbTest, col, document);
  return getDoc(docRef);
};

export const getDocuments = function (
  col: string,
  direction: "asc" | "desc",
  timestamp: number,
  row = "fdata.ctimestamp",
  l = 10
) {
  const colRef = collection(isProd ? db : dbTest, col);
  const q = query(
    colRef,
    orderBy(row, direction),
    startAt(timestamp),
    limit(l)
  );
  return getDocs(q);
};

export const getDocumentsWhere = function (
  col: string,
  direction: "asc" | "desc",
  conditionRow: string,
  conditionValue: string,
  timestamp: number,
  row = "fdata.ctimestamp",
  l = 10
) {
  const colRef = collection(isProd ? db : dbTest, col);
  const q = query(
    colRef,
    where(conditionRow, "==", conditionValue),
    orderBy(row, direction),
    startAt(timestamp),
    limit(l)
  );
  return getDocs(q);
};
export const getDocumentsAccessable = function (
  col: string,
  direction: "asc" | "desc",
  timestamp: number,
  access: string,
  l = 10
) {
  const orQuery =
    access == "assistant"
      ? or(where("data.account.access", "==", "personnel"))
      : access == "admin"
      ? or(
          where("data.account.access", "==", "personnel"),
          where("data.account.access", "==", "assistant"),
          where("data.account.access", "==", "admin")
        )
      : or(
          where("data.account.access", "==", "personnel"),
          where("data.account.access", "==", "assistant"),
          where("data.account.access", "==", "admin"),
          where("data.account.access", "==", "uys2000")
        );
  const colRef = collection(isProd ? db : dbTest, col);
  const q = query(
    colRef,
    orQuery,
    orderBy("fdata.ctimestamp", direction),
    startAt(timestamp),
    limit(l)
  );
  return getDocs(q);
};

export const getDocumentsAccessableCount = function (
  col: string,
  direction: "asc" | "desc",
  timestamp: number,
  access: string,
  l = 10
) {
  const orQuery =
    access == "assistant"
      ? or(where("data.account.access", "==", "personnel"))
      : access == "admin"
      ? or(
          where("data.account.access", "==", "personnel"),
          where("data.account.access", "==", "assistant"),
          where("data.account.access", "==", "admin")
        )
      : or(
          where("data.account.access", "==", "personnel"),
          where("data.account.access", "==", "assistant"),
          where("data.account.access", "==", "admin"),
          where("data.account.access", "==", "uys2000")
        );
  const colRef = collection(isProd ? db : dbTest, col);
  const q = query(
    colRef,
    orQuery,
    orderBy("fdata.ctimestamp", direction),
    startAfter(timestamp),
    limit(l)
  );
  return getCountFromServer(q);
};
export const getDocumentsOwned = function (
  col: string,
  direction: "asc" | "desc",
  timestamp: number,
  user: string,
  row = "fdata.ctimestamp",
  l = 10
) {
  const w = !user ? [] : [where("data.user", "==", user)];
  const colRef = collection(isProd ? db : dbTest, col);
  const q = query(
    colRef,
    ...w,
    orderBy(row, direction),
    startAt(timestamp),
    limit(l)
  );
  return getDocs(q);
};

export const getDocumentsCount = function (
  col: string,
  direction: "asc" | "desc",
  conditionRow: string | undefined,
  condition: string | undefined,
  endTimestamp: number,
  startTimestamp = Date.now(),
  targetRow = "fdata.ctimestamp"
) {
  const coll = collection(isProd ? db : dbTest, col);
  const w =
    conditionRow && condition ? [where(conditionRow, "==", condition)] : [];
  const q = query(
    coll,
    orderBy(targetRow, direction),
    ...w,
    where(targetRow, "<=", startTimestamp),
    where(targetRow, "<=", endTimestamp)
  );
  return getCountFromServer(q);
};
export const getDocumentsAggregate = function (
  col: string,
  direction: "asc" | "desc",
  conditionRow: string | undefined,
  condition: string | undefined,
  sumRow: string,
  sumRow2: string | undefined,
  endTimestamp: number,
  startTimestamp = Date.now(),
  targetRow = "fdata.ctimestamp"
) {
  const coll = collection(isProd ? db : dbTest, col);
  const w =
    conditionRow && condition ? [where(conditionRow, "==", condition)] : [];
  const q = query(
    coll,
    orderBy(targetRow, direction),
    ...w,
    where(targetRow, "<=", startTimestamp),
    where(targetRow, ">=", endTimestamp)
  );
  //eslint-disable-next-line
  const agg = { count: count(), sum: sum(sumRow) } as any;
  if (sumRow2) agg.sum2 = sum(sumRow2);
  return getAggregateFromServer(q, agg);
};
export const setDocument = function (
  col: string,
  document: string,
  data: object
) {
  const docRef = doc(isProd ? db : dbTest, col, document);
  return setDoc(docRef, JSON.parse(JSON.stringify(data)));
};

export const addDocument = function (col: string, data: object) {
  const colRef = collection(isProd ? db : dbTest, col);
  return addDoc(colRef, JSON.parse(JSON.stringify(data)));
};

export const updateDocument = function (
  col: string,
  document: string,
  data: object
) {
  const docRef = doc(isProd ? db : dbTest, col, document);
  const data_ = JSON.parse(JSON.stringify(data));
  const d = {
    ...dot.dot(data_),
    "fdata.updateCount": increment(1),
    "fdata.utimestamp": Date.now(),
  };
  console.log(d);
  return updateDoc(docRef, d);
};

export const updateDocumentFields = function (
  col: string,
  document: string,
  updates: UpdateFieldValue[],
  data: object = {}
) {
  const docRef = doc(isProd ? db : dbTest, col, document);
  const data_ = JSON.parse(JSON.stringify(data));
  const d = {
    ...dot.dot(data_),
    "fdata.updateCount": increment(1),
    "fdata.utimestamp": Date.now(),
  };
  updates.forEach((update) => (d[update.key] = increment(update.value)));
  return updateDoc(docRef, {
    "fdata.updateCount": increment(1),
    "fdata.utimestamp": Date.now(),
    ...d,
  });
};

export const deleteDocument = function (col: string, document: string) {
  const docRef = doc(isProd ? db : dbTest, col, document);
  return deleteDoc(docRef);
};
