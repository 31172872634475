import Swal from "sweetalert2";

export const successMessage = function (title: string, message: string) {
  return Swal.fire({
    icon: "success",
    title: title,
    text: message,
  });
};

export const errorMessage = function (title: string, message: string) {
  return Swal.fire({
    icon: "error",
    title: title,
    text: message,
  });
};
