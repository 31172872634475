<template>
  <router-view />

  <u-loader :show="showLoader" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ULoader from './components/common/ULoader.vue';

import { getAuthTokenResult } from "@/services/firebase/auth"
import { Unsubscribe, onAuthStateChanged, User, getUserAuth } from './services/firebase/auth';

import { setPrefence } from "@/services/capacitor/prefences"
import { SETTING, USER } from './constraints';
import { getDocument } from './services/firebase/firestore';
import { errorMessage } from './services/sweetAlert';

export default defineComponent({
  components: { ULoader },
  data() {
    return {
      showLoader: false,
      stateListener: null as null | Unsubscribe,
    }
  },
  methods: {
    async setUser() {
      try {
        const auth = getUserAuth.logger()
        if (!auth) return false;
        setPrefence("auth", { id: auth.uid, mail: auth.email, password: "" })

        const snapshot = await getDocument.pLogger(USER, auth.uid)
        if (snapshot.exists()) setPrefence.pLogger("user", snapshot.data())
        return true
      } catch {
        errorMessage("Hata", "Serivise bağlanılamadı internetinizi kontrol edin").then(() => location.reload())
      }
    },
    async setAccess() {
      const result = await getAuthTokenResult()
      if (!result) return;
      setPrefence("access", result.claims.access as string)
    },
    async setSettings() {
      const setting = await getDocument.pLogger(SETTING, "SETTING")
      if (setting.exists()) {
        setPrefence("SETTING", setting.data())
      }
    },
    async runInLoader(callback: (...args: unknown[]) => unknown) {
      this.showLoader = true
      const result = await callback()
      this.showLoader = false
      return result
    },
    async signInAction() {
      try {
        if (!await this.setUser()) throw "Auth Error";
        this.setAccess()
        this.setSettings()
        this.$router.push({ name: "HomeView" })
      }
      catch {
        this.$router.push({ name: "LoginView" })
      }
    },
    wathcAuth(user: User | null) {
      if (!user) { this.$router.push({ name: "LoginView" }); return; }
      this.runInLoader(this.signInAction); return;
    }
  },
  computed: {
    settings() {
      return window.settings
    }
  },
  watch: {
    settings(n, o) {
      console.log("-----------update", n, o)
    }
  },
  mounted() {
    this.stateListener = onAuthStateChanged(this.wathcAuth)
  }
})
</script>