import { Preferences } from "@capacitor/preferences";

export const getPrefenceKeys = function () {
  return Preferences.keys();
};
export const setPrefence = function (key: string, value: object | string) {
  return Preferences.set({ key: key, value: JSON.stringify(value) });
};
export const getPrefence = function <T>(key: string) {
  return Preferences.get({ key: key }).then(({ value }) =>
    value ? (JSON.parse(value) as T) : null
  );
};
export const removePrefence = function (key: string) {
  return Preferences.remove({ key: key });
};

export const clearPrefences = function () {
  return Preferences.clear();
};
