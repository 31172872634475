import { MAIL } from "@/constraints";
import { app } from "@/services/firebase/app";
import {
  getAuth,
  onAuthStateChanged as _onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as _signOut,
  NextOrObserver,
  ErrorFn,
  CompleteFn,
} from "firebase/auth";
import { User, Unsubscribe } from "firebase/auth";

export const auth = getAuth(app);

export const getUserAuth = function () {
  return auth.currentUser;
};

export const getAuthToken = async function () {
  const user = getUserAuth();
  if (user) return await user.getIdToken(true);
  return undefined;
};

export const getAuthTokenResult = async function () {
  const user = getUserAuth();
  if (user) return await user.getIdTokenResult();
  return undefined;
};
export const signIn = function (username: string, password: string) {
  return signInWithEmailAndPassword(auth, username + MAIL, password);
};

export const signOut = function () {
  return _signOut(auth);
};

export const onAuthStateChanged = function (
  callback: NextOrObserver<User>,
  callbackError?: ErrorFn,
  callbackCompleted?: CompleteFn
) {
  return _onAuthStateChanged(auth, callback, callbackError, callbackCompleted);
};

export { Unsubscribe, User };
